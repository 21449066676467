import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import AskQuestion from 'subsys/ask-question';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';
import { ModalButton, Content } from 'components/modal';
import LangSwitch from 'components/lang-switch';

import linksDomik from 'src-sites/domik/links';
import linksForum from 'src-sites/forum/links';
import defaultLogoForum from 'src-sites/forum/assets/logo-forum-full.jpg';

import translates from './footer-i18n';

const classesFooter = new Bem('footer');
const bemClassesLogo = new Bem('logoHeader');

@I18nHoc(translates())
export default class Footer extends Component {
  static componentCacheConfig = {
    strategy: 'template',
    enable: true
  };

  static propTypes = {
    className: PropTypes.string,
    location: PropTypes.shape({}),
  };

  static renderLogoContent(logoClasses) {
    return (
      <>
        <Link
          {...logoClasses('link', 'toDomik')}
          builder={linksDomik.sectionDomik}
          builderProps={{ title: 'domik.ua' }}
          noRedirector
        >
          <span {...logoClasses('linkContent')}>
            <Icon {...logoClasses('icon', 'logoDomikInForum')} type={iconTypes.logoDomik} />
          </span>
        </Link>
        <Link
          {...logoClasses('link', 'toForum')}
          builder={linksForum.sectionForum}
          builderProps={{ title: 'forum.domik.ua' }}
        >
          <span {...logoClasses('linkContent')}>
            <Icon {...logoClasses('icon', 'logoForum')} type={iconTypes.logoForum} />
          </span>
        </Link>
      </>
    );
  }

  render() {
    const { i18n } = this;
    const { location, activeRoute } = this.props;
    const seoLocation = get(location, 'pathname');
    const name = 'Forum.domik.ua';
    const foundingDate = `2001-${(new Date()).getFullYear()}`;

    return (
      <footer {...classesFooter(null, null, this.props.className)}>
        <Container body>
          <Row {...classesFooter('row', 'main')} mpColumn>
            <Col {...classesFooter('col', 'first')} allFlex mdJustifyCCenter mtJustifyCCenter allPr={0}>
              <div {...classesFooter('item', 'logoContainer')}>
                <div
                  {...bemClassesLogo(null, null, classesFooter('logo').className)}
                  children={Footer.renderLogoContent(bemClassesLogo)}
                />
              </div>
              <div {...classesFooter('item', 'askQuestion')}>
                <ModalButton
                  {...classesFooter('btn', 'toAskQuestion')}
                  buttonProps={{
                    iconType: iconTypes.comment,
                    label: i18n('askQuestionBtn'),
                    title: i18n('askQuestionBtn'),
                    contentLeft: true,
                    dataView: null
                  }}
                  size="big"
                  disableNotification={false}
                >
                  <Content closeButton noPadding adaptiveHeight>
                    <div {...classesFooter('modalContent')}>
                      <AskQuestion
                        {...classesFooter('blockAskQuestion')}
                        controller="support/ask-question/ask-us-question"
                        entity="ask_question"
                        url={seoLocation}
                        otherProps={{
                          notificationMessageSuccess: i18n('askQuestionNotification'),
                          inputPhoneProps: { noLabel: true, noElementBefore: true },
                          additionalMain: true,
                          headerTopic: true,
                          headerTopicText: i18n('askQuestionModalTopic')
                        }}
                      />
                    </div>
                  </Content>
                </ModalButton>
              </div>
              <div {...classesFooter('item', 'lang')}>
                <LangSwitch activeRoute={activeRoute} />
              </div>
              <div {...classesFooter('item', 'link')}>
                <Link
                  {...classesFooter('link', 'toRules')}
                  builder={linksForum.sectionTopic}
                  builderProps={{
                    topicUrl: '/domik-rules-t19531.html',
                    topic_title: i18n('conceptRulesForum'),
                    hash: '#p267480'
                  }}
                  noRedirector
                >
                  <span {...classesFooter('linkContent')}>
                    <span {...classesFooter('text')} children={i18n('conceptRulesForum')} />
                  </span>
                </Link>
                <Link
                  {...classesFooter('link', 'toTariff')}
                  builder={linksDomik.sectionTariffPlans}
                  builderProps={{
                    title: i18n('tariffPlans')
                  }}
                  noRedirector
                  blank
                >
                  <span {...classesFooter('linkContent')}>
                    <span {...classesFooter('text')} children={i18n('tariffPlans')} />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <div {...classesFooter('bottom')}>
          <Container body>
            <Row {...classesFooter('row', 'bottom')}>
              <Col {...classesFooter('col', 'copy')} allPr={0}>
                <div {...classesFooter('item', 'copyContainer')}>
                  <span {...classesFooter('text')}>
                    &copy; {`${foundingDate}\u00a0${i18n('realEstatePortal')} ${name}\u00ae ${i18n('footerBottomDescText')}`}
                  </span>
                </div>
              </Col>
              <Col {...classesFooter('col', 'logoV')} allAuto allPr={0}>
                <div {...classesFooter('item', 'logoV')}>
                  <Link
                    {...classesFooter('link', 'logoV noUnderline')}
                    href="//vintage.com.ua"
                    title={i18n('linkToVintageTitle')}
                    rel="nofollow"
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children="by" />
                      <Icon {...classesFooter('icon', 'logoV')} type={iconTypes.logoV} />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            logo: absoluteUrl(defaultLogoForum),
            name,
            url: absoluteUrl(''),
            foundingDate,
          }}
        />
      </footer>
    );
  }
}
