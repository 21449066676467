import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';

import translates from './helmet-i18n.json';

import favicon from '../assets/favicon-forum.ico';

@I18nHoc(translates)
export default class HelmetI18n extends Component {
  static propTypes = {
    location: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const { location } = this.props;
    const siteName = i18n('siteName');

    return (
      <Helmet
        defaultTitle="Forum.domik.ua"
        titleTemplate="%s"
      >
        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={absoluteUrl(location.pathname, location.search)} />
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />
      </Helmet>
    );
  }
}
