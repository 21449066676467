import menuBaseTranslates from 'components/header/side-menu/menu-i18n.json';

import menuTranslatesDomik from 'src-sites/domik/body/header/header-i18n';
import menuTranslates from '../menu-i18n.json';

export default function () {
  return {
    ...menuBaseTranslates,
    ...menuTranslatesDomik(),
    ...menuTranslates,

    searchOnForumDomikUA: {
      ru: 'Поиск на Forum.domik.ua',
      ua: 'Пошук на Forum.domik.ua'
    }
  };
}
