export default function () {
  return {
    askQuestionBtn: {
      ru: 'Написать нам',
      ua: 'Написати нам'
    },
    askQuestionNotification: {
      ru: 'Ваше сообщение отправлено администратору.\nМы ответим на ваш вопрос в ближайшее время.\nСпасибо!',
      ua: 'Ваше повідомлення відправлено адміністратору.\nМи відповімо на ваше запитання найближчим часом.\nДякуємо!'
    },
    askQuestionModalTopic: {
      ru: 'Напишите нам',
      ua: 'Напишіть нам'
    },

    tariffPlans: {
      ru: 'Реклама новостроек',
      ua: 'Реклама новобудов'
    },
    conceptRulesForum: {
      ru: 'Концепция и правила форума Domik.ua',
      ua: 'Концепція і правила форуму Domik.ua'
    },
    realEstatePortal: {
      ru: 'Портал недвижимости',
      ua: 'Портал нерухомості'
    },
    footerBottomDescText: {
      ru: 'Все права защищены.',
      ua: 'Всі права захищені.'
    },

    linkToVintageTitle: {
      ru: 'VINTAGE - СОЗДАТЕЛИ ЛУЧШИХ САЙТОВ В МИРЕ',
      ua: 'VINTAGE - ТВОРЦІ КРАЩИХ САЙТІВ В СВІТІ'
    }
  };
}
